.App {
  width: 100%;
  height: 100vh;
  background: #ac50fc;
  background: -webkit-radial-gradient(center, #ac50fc, #4f1799);
  background: -moz-radial-gradient(center, #ac50fc, #4f1799);
  background: radial-gradient(ellipse at center, #ac50fc, #4f1799);
}
.app_wrap {
  width: 98%;
  height: 100%;
  margin-left: 1%;
  border-radius: 12px;
  color: white;
}
.qr_stare {
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  text-align: center;
  font-size: 3vw;
  font-weight: 600;
  color: white;
}
.qr_bet_details {
  margin-top: 12px;
  display: flex;
  width: 90%;
  margin-left: 5%;
  border-width: 6px;
  border-style: solid;
  border-image: linear-gradient(
      to left,
      rgba(255, 255, 255, 1),
      rgba(255, 0, 0, 0),
      rgba(255, 255, 255, 1)
    )
    3 30%;
  border-left: none;
  border-right: none;
  border-radius: 24px;
}
.bet_left {
  width: 30%;
}
.bet_right {
  width: 80%;
}
.bet_img_type {
  margin-top: 60px;
  margin-left: 60px;
}
.bet_right_serial {
  font-size: 3vw;
  font-weight: 700;
  text-align: center;
  font-family: Consolas;
}
.bet_right_data {
  margin-top: 21px;
}
.moneybag {
  width: 30%;
  height: 50%;
}
.bet_right_data,
.bet_right_stake,
.bet_right_tax {
  font-size: 1vw;
  color: lightgrey;
  margin-bottom: 24px;
}
.bet_right_data span,
.bet_right_stake span,
.bet_right_tax span {
  color: white;
  font-size: 1.5vw;
  font-weight: 600;
  margin-top: -6px;
}
.wred {
  color: white;
}
.wgreen {
  color: rgb(50, 205, 50);
}
.wyellow {
  color: yellow;
}
.bet_win_amount {
  font-size: 2.7vw;
  font-weight: 600;
  color: rgb(50, 205, 50);
}
.bet_win_amount span {
  font-size: 1vw;
  font-weight: 400;
}
.bet_brut {
  font-size: 1.5vw;
  margin-top: 18px;
  margin-bottom: 12px;
}
.bet_impozit {
  font-size: 1.5vw;
}
.bet_brut span {
  font-size: 1.8vw;
  float: right;
  margin-top: -6px;
}
.bet_impozit span {
  font-size: 1.8vw;
  float: right;
  margin-top: -6px;
}
.qr_lines {
  width: 90%;
  margin-left: 5%;
  height: auto;
  max-height: 330px;
  overflow: auto;
  background-color: ghostwhite;
  border-radius: 12px;
  color: black;
}
.line_root {
  height: 90px;
  border-bottom: 1px dashed black;
  padding: 3px 12px;
}
.line_root:last-of-type {
  border-bottom: none;
}
.line_green {
  color: green;
}
.line_red {
  color: red;
}
.line_line1 {
  font-size: 1.5vw;
  margin-bottom: 9px;
}
.line_line1 span {
  float: right;
  margin-right: 12px;
}
.line_line2 {
  font-size: 1vw;
}
.line_line2 span {
  float: right;
  margin-right: 12px;
  color: black;
}
.qr_redirecter {
  text-align: center;
  background-color: rgb(50, 205, 50);
  width: 10%;
  margin: 0 auto;
  border-radius: 12px;
  cursor: pointer;
  color: black;
  font-size: 1vw;
  padding: 12px;
  font-weight: 600;
}
@media all and (max-width: 768px) {
  .app_wrap {
    width: 100%;
    margin: 0;
    border-radius: 0;
  }
  .qr_stare {
    font-size: 9vw;
    line-height: 2.4;
    font-weight: 800;
  }
  .bet_left {
    width: 60%;
  }
  .bet_img_type {
    width: 81%;
    height: 81%;
    margin-top: 18px;
    margin-left: 0;
  }
  .moneybag {
    width: 66%;
    height: 72%;
  }
  .bet_right_serial {
    font-size: 7.2vw;
    font-weight: 700;
  }
  .bet_right_data,
  .bet_right_stake,
  .bet_right_tax {
    font-size: 2.7vw;
    color: lightgrey;
    margin-bottom: 24px;
  }
  .bet_right_data span,
  .bet_right_stake span,
  .bet_right_tax span {
    color: white;
    font-size: 4.2vw;
    font-weight: 600;
  }
  .bet_win_amount {
    font-size: 8vw;
    font-weight: 600;
    color: rgb(50, 205, 50);
  }
  .bet_win_amount span {
    font-size: 4vw;
    font-weight: 400;
  }
  .bet_brut {
    font-size: 3vw;
    margin-top: 18px;
    margin-bottom: 12px;
  }
  .bet_impozit {
    font-size: 3vw;
  }
  .bet_brut span {
    font-size: 5vw;
    float: right;
    margin-top: -6px;
  }
  .bet_impozit span {
    font-size: 5vw;
    float: right;
    margin-top: -6px;
  }

  .line_root {
    height: 72px;
    border-bottom: 1px dashed black;
    padding: 3px 12px;
  }
  .line_root:last-of-type {
    border-bottom: none;
  }
  .line_green {
    color: green;
  }
  .line_red {
    color: red;
  }
  .line_line1 {
    font-size: 4.2vw;
    margin-bottom: 9px;
  }
  .line_line1 span {
    float: right;
    margin-right: 12px;
  }
  .line_line2 {
    font-size: 3vw;
  }
  .line_line2 span {
    float: right;
    margin-right: 12px;
    color: black;
  }
  .qr_redirecter {
    text-align: center;
    background-color: rgb(50, 205, 50);
    width: 70%;
    margin: 0 auto;
    border-radius: 12px;
    cursor: pointer;
    color: black;
    font-size: 3.9vw;
    padding: 12px;
    font-weight: 600;
  }
}
